import { Injectable } from '@angular/core';
import {ApiCallService} from '../../../services/api/api-call.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, zip} from 'rxjs';
import {MandantenService} from '../../../services/mandanten/mandanten.service';
import {KundeService} from '../../../services/kunden/kunde.service';
import {MatDialog} from '@angular/material/dialog';
import {MenuVorauswahlEditorComponent} from '../components/editor/editor.component';
import {SeitenleisteInhalt} from '../../obs.seitenleiste/seitenleisteInhalt.class';
import {MenuVorauswahlSeitenleisteComponent} from '../../obs.seitenleiste/components/menuVorauswahl/menuVorauswahl.seitenleiste.component';
import {SeitenleisteService} from '../../obs.seitenleiste/seitenleiste.service';
import {
  SpeiseplanWarenkorbUpdateComponent
} from '../../obs.speiseplan/einzelbesteller/speiseplanWarenkorbUpdate/speiseplanWarenkorbUpdate.component';
import {MitteilungsService} from '../../../services/prozesse/mitteilung.service';

@Injectable()
export class MenuVorauswahlService {

  constructor(
    private api_call_service: ApiCallService,
    private mandantService: MandantenService,
    private kundenService: KundeService,
    private matDialog: MatDialog,
    private seitenleistenService: SeitenleisteService,
    private mitteilungsService: MitteilungsService,
    ){
  }

  public menuVorauswahl = new BehaviorSubject({})
  private nebenKomponenten

  public menuVorauswahlInit(){
    return zip(
      this.mandantService.mandant_get(),
      this.kundenService.kunde_get()
    )
  }

  public menuVorauswahlGet(mandant, kunde, zeitraum){
    this.api_call_service.request('/callV2', 'MenueVorauswahl/Get', {
      mandantId: environment.mandant_id,
      kundeId: kunde.id,
      von: zeitraum.start,
      bis: zeitraum.ende,
    }).subscribe(response => {
      if (!this.validateVorauswahlAufDatenstruktur(response)){
        this.mitteilungsService.setMitteilung(99001)
      }
      this.menuVorauswahl.next(response)
      this.updateMenuVorauswahlSeitenleiste(mandant, null, null, null, null, Object.values(response)[0])
    }, error => {
      let messageId = error.error.content.msgId
      this.mitteilungsService.setMitteilung(messageId)
    })
  }

  public menuVorauswahlEditor(menu, tag, mandant){
    this.matDialog.open(
      MenuVorauswahlEditorComponent, {
        width: '1000px',
        panelClass: 'menuVorauswahlEditor',
        data: {menu: menu, tag: tag, mandant: mandant}
      }
    )
  }

  public updateMenuVorauswahlSeitenleiste(mandant, haupt = null, buffer = null, menu = null, index = null, tag = null){
    if (buffer && menu && index && tag){
      this.ermittleNebenkomponenten(buffer, menu, index, tag)
    }
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      MenuVorauswahlSeitenleisteComponent, {
        mandant: mandant,
        menu: haupt,
        tag: tag,
        neben: this.nebenKomponenten
      })
    )
  }

  public menuVorauswahlCommit(menueVorauswahlTage){
    let menuVorauswahlAPIObjekt = []
    for (let datum in menueVorauswahlTage){
      menuVorauswahlAPIObjekt.push({
        datum: datum,
        menues: menueVorauswahlTage[datum].vorausgewaehlt.menues ? menueVorauswahlTage[datum].vorausgewaehlt.menues : {},
        bestellZeit: Date.now(),
        splanId: menueVorauswahlTage[datum].splanId,
        error: null
      })
    }
    this.api_call_service.request('/callV2', 'MenueVorauswahl/Commit', {
      mandantId: environment.mandant_id,
      menueVorauswahlTage: JSON.stringify(menuVorauswahlAPIObjekt),
    }).subscribe(response => {
      if (response && response['errorType']){
        this.mitteilungsService.setMitteilung(response['msgId'])
      }else{
        this.mitteilungsService.setMitteilung(8901)
      }
    })
  }

  private validateVorauswahlAufDatenstruktur(vorauswahlDaten){
    for (let datum in vorauswahlDaten){
      for (let index in vorauswahlDaten[datum].vorausgewaehlt.menues){
        if (Array.isArray(vorauswahlDaten[datum].vorausgewaehlt.menues[index])){
          return false
        }
      }
    }
    return true
  }

  private ermittleNebenkomponenten(buffer, menu, index, tag){
    let sammlung = []
    let id: any
    for (id of Object.values(buffer.menues[menu.menueNr])){
      let nebenKomponente
      if (id != index && tag.tagesMenues[id]){
        nebenKomponente = tag.tagesMenues[id.replace('M', '')]
        sammlung.push(nebenKomponente)
      }
    }
    this.nebenKomponenten = sammlung
  }
}
